/* @flow */
/* eslint-disable camelcase, react/no-unused-state */
import Auth from 'Auth';
import {
  AlertIcon,
  AllDoneIcon,
  BackupIcon,
  BitcoinIcon,
  CloudpaymentsIcon,
  PaypalIcon,
  SbrfIcon,
  StarsIcon,
  StripeIcon,
  YandexMoneyIcon,
} from 'Icons';
import crying from 'Icons/crying.jpg';
import { fetchOrders, fetchSearch, refundOrder } from 'actions';
import { Popconfirm } from 'antd';
import { ROLES } from 'constants.js';
import {
  Cry,
  Donor,
  DonorItem,
  DonorItemLikeLink,
  DonorItemLink,
  DonorsTitle,
  DonorsTitleItem,
  DownloadButton,
  StyledTable,
} from 'containers/styled';
import { differenceInMonths, format, parse } from 'date-fns';
import locale from 'date-fns/locale/ru';
import fileDownload from 'js-file-download';
import lodashGet from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { cardDataSelector, orderRefundStatus } from 'selectors.js';
import { PaymentMethod, currency, decrypt } from 'utils';
import { toSearch } from 'utils/link';
import { getOffset } from 'utils/pagination';
import { Get } from 'utils/requester';
import {
  AlertContainer,
  ContainerTitle,
  ContentContainer,
  Footer,
  LoaderStyled,
  Months,
  StyledDonorItem,
} from './styled';

export const serviceIconsMap = {
  stripe: StripeIcon,
  paypal: PaypalIcon,
  yandex_dengi: YandexMoneyIcon,
  direct: SbrfIcon,
  bitcoin: BitcoinIcon,
  cloudpayments: CloudpaymentsIcon,
};

const getRegularColumns = ({ privateKey }) => [
  {
    title: '',
    width: 40,
  },
  {
    title: () => <b>Дата</b>,
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date) => (
      <DonorItem>{format(parse(date), 'DD MMM YYYY, HH:mm', { locale })}</DonorItem>
    ),
    width: 150,
  },
  {
    title: () => <b>Сервис</b>,
    dataIndex: 'service',
    key: 'service',
    render: (service) => {
      const ServiceIconComponent = serviceIconsMap[service];
      return <ServiceIconComponent /> || PaymentMethod[service];
    },
    width: 100,
  },
  {
    title: () => <b>Сумма</b>,
    dataIndex: 'gross_sum',
    key: 'gross_sum',
    render: (sum, item) => (
      <DonorItem>
        <b>{`${sum} ${currency[item.gross_curr.toUpperCase()]}`}</b>
      </DonorItem>
    ),
    width: 100,
  },
  {
    title: () => <b>Регион</b>,
    dataIndex: 'region',
    key: 'region',
    render: (region) => (
      <DonorItem>
        <b>{`${region?.toUpperCase()}`}</b>
      </DonorItem>
    ),
    width: 60,
  },
  {
    title: () => <b>Бранч</b>,
    dataIndex: 'branch',
    key: 'branch',
    render: (branch) => (
      <DonorItem>
        <b>{`${branch?.toUpperCase()}`}</b>
      </DonorItem>
    ),
    width: 60,
  },
  {
    title: () => <b>Телефон</b>,
    dataIndex: 'phone',
    key: 'phone',
    render: (phone) => (
      <DonorItem>{decrypt(phone, privateKey) || '-'}</DonorItem>
    ),
    ellipsis: true,
    width: '20%',
  },
  {
    title: () => <b>Электронная почта </b>,
    dataIndex: 'email',
    key: 'email',
    render: (email) => (
      <DonorItemLink to={toSearch(email)} isLink>
        {decrypt(email, privateKey)}
      </DonorItemLink>
    ),
    ellipsis: true,
    width: '20%',
  },
  {
    title: () => <b>Вид донора</b>,
    dataIndex: '',
    render: (_, item) => (
      <DonorItem>
        {item.recurrent && <StarsIcon />}
        {item.recurrent && <Months>{item.transactionCount}</Months>}
        {item['recurrentModel.createdAt']
          && `(${
            differenceInMonths(new Date(), item['recurrentModel.createdAt']) + 1
          } мес)`}
        {!item.recurrent && 'Единоразовый'}
      </DonorItem>
    ),
    width: 140,
  },
];

type OrdersProps = {
  count: number,
  limit: number,
  offset: number,
  dateRange: {
    startDate: string,
    endDate: string,
  },
  dispatch: (Object) => void,
  orders: OrderType[],
  isSearch: boolean,
  loading: boolean,
  privateKey: string,
  subTitle: string,
};

type OrdersState = {
  count: number,
  offset: number,
  fileLoading: boolean,
};

class Orders extends React.Component<OrdersProps, OrdersState> {
  constructor(props: OrdersProps) {
    super(props);
    this.state = {
      count: 0,
      offset: 0,
      fileLoading: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(() => ({
      count: nextProps.count,
    }));
  }

  handleRefund = (order: OrderType) => {
    const { id } = order;
    const { dispatch } = this.props;
    dispatch(refundOrder(id));
  };

  chooseRender = () => {
    const { isSearch } = this.props;
    return isSearch ? this.renderSearch() : this.renderUsual();
  };

  searchDonor = (email: string) => {
    const { dispatch } = this.props;
    dispatch(fetchSearch(email, 'email'));
  };

  handlePageClick = (page, limit) => {
    const { dateRange, dispatch } = this.props;
    const offset = getOffset(page, limit);
    this.setState(
      () => ({
        offset,
      }),
      () => dispatch(fetchOrders({ dateRange, limit, offset })),
    );
  };

  downloadFile = async () => {
    this.setState({ fileLoading: true });
    const file = await Get('/admin/order/list');
    fileDownload(file.data, 'orders.csv');
    this.setState({ fileLoading: false });
  }

  renderUsual = () => {
    const { orders, privateKey, loading } = this.props;
    const { count, fileLoading } = this.state;
    return (
      <div>
        <StyledTable
          useFixedHeader
          pagination={{
            defaultPageSize: 50,
            total: count,
            onChange: this.handlePageClick,
          }}
          columns={getRegularColumns({
            privateKey,
            search: (item) => this.searchDonor(item),
          })}
          dataSource={orders}
          loading={loading}
        />
        <Footer>
          <Popconfirm title="Точно скачать? Это займет продолжительное время и может привести к ошибке!" okText="Да" cancelText="Нет" onConfirm={this.downloadFile}>
            <DownloadButton
              loading={fileLoading}
            >
              Скачать платежи
            </DownloadButton>
          </Popconfirm>
        </Footer>
      </div>
    );
  };

  renderSearch = () => {
    const { orders, privateKey } = this.props;
    return (
      <div>
        <DonorsTitle>
          <DonorsTitleItem>Дата</DonorsTitleItem>
          <DonorsTitleItem w="5%" />
          <DonorsTitleItem>Сумма</DonorsTitleItem>
          <DonorsTitleItem>Электронная почта</DonorsTitleItem>
          <DonorsTitleItem>Телефон</DonorsTitleItem>
          <DonorsTitleItem>Карта</DonorsTitleItem>
          <DonorsTitleItem />
        </DonorsTitle>
        <ContentContainer>
          {orders.map((order) => {
            const orderCardData = cardDataSelector(order, 'order');
            const ServiceIconComponent = serviceIconsMap[order.service];
            const refundState = orderRefundStatus(order);

            return (
              <Donor key={order.id}>
                <DonorItem>
                  {format(order.createdAt, 'DD MMM YYYY, HH:mm', { locale })}
                </DonorItem>
                <DonorItem w="5%">
                  {ServiceIconComponent ? (
                    <ServiceIconComponent />
                  ) : (
                    order.service
                  )}
                </DonorItem>
                <StyledDonorItem>
                  {order.message && (
                    <AlertContainer data-tip={order.message}>
                      <AlertIcon />
                      <ReactTooltip place="bottom" type="dark" effect="solid" />
                    </AlertContainer>
                  )}
                  <strong>
                    {`${order.gross_sum} ${currency[order.gross_curr?.toUpperCase()] || ''}`}
                  </strong>
                </StyledDonorItem>
                <DonorItemLink to={toSearch(order.email)} isLink>
                  {decrypt(order.email, privateKey)}
                </DonorItemLink>
                <DonorItem>{decrypt(order.phone, privateKey) || '-'}</DonorItem>
                <DonorItem>
                  <strong>
                    {' '}
                    {lodashGet(orderCardData, 'cardNumber', '')}
                    {' '}
                  </strong>
                  {lodashGet(orderCardData, 'expDate', '')}
                  {!orderCardData && '—'}
                </DonorItem>
                {refundState === 'not' && <DonorItem />}
                {refundState === 'failed' && <DonorItem>failed</DonorItem>}
                {Auth.allowFor(ROLES.superadmin)
                  && refundState === 'refundable' && (
                    <DonorItemLikeLink>
                      <div
                        role="button"
                        aria-hidden
                        onClick={() => this.handleRefund(order)}
                      >
                        <BackupIcon />
                        Вернуть
                      </div>
                    </DonorItemLikeLink>
                  )}
                {Auth.allowFor(ROLES.superadmin) && refundState === 'refunded' && (
                  <DonorItem>
                    <AllDoneIcon />
                    Возвращено
                  </DonorItem>
                )}
                {Auth.allowFor(ROLES.superadmin)
                  && refundState === 'processing' && (
                    <DonorItem>
                      <LoaderStyled size="2px" />
                      Подождите
                    </DonorItem>
                  )}
              </Donor>
            );
          })}
        </ContentContainer>
      </div>
    );
  };

  render() {
    const { orders, subTitle } = this.props;
    return (
      <div>
        {subTitle && <ContainerTitle>{subTitle}</ContainerTitle>}
        {orders ? (
          this.chooseRender()
        ) : (
          <Cry>
            <img src={crying} alt="Cry" />
          </Cry>
        )}
      </div>
    );
  }
}

export default connect((state: FullStore): {
  pageCount: number,
  privateKey: string,
} => ({
  count: state.orders.count,
  loading: state.orders?.loading,
  privateKey: state.privateKey.value,
}))(Orders);
