/* @flow */
import { StarsIcon } from 'Icons';
import { fetchSearch } from 'actions';
import { Popconfirm } from 'antd';
import EditDonor from 'components/Modals/EditDonor';
import { errorsMap, serviceIconsMap } from 'constants.js';
import {
  Donor,
  DonorItem,
  DonorsTitle,
  DonorsTitleItem,
  DownloadButton,
  StyledTable,
} from 'containers/styled';
import { differenceInMonths, format, parse } from 'date-fns';
import locale from 'date-fns/locale/ru';
import fileDownload from 'js-file-download';
import lodashGet from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cardDataSelector } from 'selectors';
import { PaymentMethod, currency, decrypt } from 'utils';
import { toSearch } from 'utils/link';
import { Get } from 'utils/requester';
import ExternalLink from './externalLink';
import {
  ContentContainer,
  DateStatus,
  DateStatusAddon,
  Footer,
  Months,
  StatusSubscription,
} from './styled';

const getRegularColumns = (privateKey, handleClick) => [
  {
    title: '',
    width: 40,
  },
  {
    title: () => <b>Дата начала</b>,
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date) => (
      <DonorItem>{format(parse(date), 'DD MMM YYYY, H:mm', { locale })}</DonorItem>
    ),
    width: 150,
  },
  {
    title: () => <b>Сервис</b>,
    dataIndex: 'service',
    key: 'service',
    render: (service) => {
      const ServiceIconComponent = serviceIconsMap[service];
      return <ServiceIconComponent /> || PaymentMethod[service];
    },
    width: 100,
  },
  {
    title: () => <b>Сумма</b>,
    dataIndex: 'gross_sum',
    key: 'gross_sum',
    render: (sum, item) => (
      <DonorItem>
        <b>{`${sum} ${currency[item.gross_curr.toUpperCase()]}`}</b>
      </DonorItem>
    ),
    width: 100,
  },
  {
    title: () => <b>Регион</b>,
    dataIndex: 'region',
    key: 'region',
    render: (region) => (
      <DonorItem>
        <b>{`${region?.toUpperCase()}`}</b>
      </DonorItem>
    ),
    width: 60,
  },
  {
    title: () => <b>Бранч</b>,
    dataIndex: 'branch',
    key: 'branch',
    render: (branch) => (
      <DonorItem>
        <b>{`${branch?.toUpperCase()}`}</b>
      </DonorItem>
    ),
    width: 60,
  },
  {
    title: () => <b>Дата окончания</b>,
    dataIndex: 'date_end',
    key: 'date_end',
    render: (date, item) => date ? (
      <DateStatus color={errorsMap[item.status].color}>
        {format(item.date_end, 'DD MMMM YYYY', { locale })}
        <DateStatusAddon>
          (
          {errorsMap[item.status].text}
          )
        </DateStatusAddon>
      </DateStatus>
      ) : (
        <StatusSubscription color={errorsMap[item.status].color}>
          {errorsMap[item.status].text}
        </StatusSubscription>
      ),
    width: 150,
  },
  {
    title: () => <b>Телефон</b>,
    dataIndex: 'phone',
    key: 'phone',
    render: (phone) => (
      <DonorItem>{decrypt(phone, privateKey) || '-'}</DonorItem>
    ),
    ellipsis: true,
  },
  {
    title: () => <b>Электронная почта </b>,
    dataIndex: 'email',
    key: 'email',
    render: (email, item) => (
      <DonorItem onClick={() => handleClick(item)} isLink>
        {decrypt(email, privateKey)}
      </DonorItem>
    ),
    ellipsis: true,
  },
  {
    title: '',
    width: 40,
  },
];

type RecurrentRenderProps = {
  changePage(mixed): void,
  isSearch: boolean,
  history: {
    push: (string) => void,
  },
  dispatch(Object): void,
  recurrents: RecurrentType[],
  privateKey: string,
  pageCount: number,
  offset: number,
  limit: number,
  count: number,
  loading: boolean,
};

type RecurrentRenderState = {
  isModalOpen: boolean,
  donor: ?RecurrentType,
  fileLoading: boolean,
};

class Recurrents extends React.Component<
  RecurrentRenderProps,
  RecurrentRenderState
> {
  constructor(props: RecurrentRenderProps) {
    super(props);
    this.state = {
      isModalOpen: false,
      donor: null,
      fileLoading: false,
    };
  }

  chooseRender = () => {
    const { isSearch } = this.props;
    return isSearch ? this.renderSearch() : this.renderUsual();
  };

  handlePageClick = (page: number, limit: number) => {
    const { changePage } = this.props;
    changePage({ page, limit });
  };

  handleEmailClick = (donor) => {
    const { isSearch, history } = this.props;
    if (isSearch) {
      this.setState((state) => ({
        isModalOpen: !state.isModalOpen,
        donor,
      }));
    } else {
      history.push(toSearch(donor.email));
    }
  };

  closeModal = () => {
    this.setState(() => ({
      isModalOpen: false,
    }));
  };

  searchDonor = (email: string) => {
    const { dispatch } = this.props;
    dispatch(fetchSearch(email));
  };

  downloadFile = async () => {
    this.setState({ fileLoading: true });
    const file = await Get('/admin/recurrent/list');
    fileDownload(file.data, 'recurrents.csv');
    this.setState({ fileLoading: false });
  };

  renderUsual = () => {
    const {
      recurrents, privateKey, count, loading,
    } = this.props;
    const { fileLoading } = this.state;
    return (
      <div>
        <StyledTable
          pagination={{
            defaultPageSize: 50,
            total: count,
            onChange: this.handlePageClick,
          }}
          columns={getRegularColumns(privateKey, (item) => this.handleEmailClick(item))}
          dataSource={recurrents}
          loading={loading}
        />
        <Footer>
          <Popconfirm title="Точно скачать? Это займет продолжительное время и может привести к ошибке!" okText="Да" cancelText="Нет" onConfirm={this.downloadFile}>
            <DownloadButton loading={fileLoading}>
              Скачать подписки
            </DownloadButton>
          </Popconfirm>
        </Footer>
      </div>
    );
  };

  renderSearch = () => {
    const { recurrents, privateKey, isSearch } = this.props;
    return (
      <div>
        {isSearch && (
          <ExternalLink
            id={recurrents[0].foreign_recurrent_id}
            service={recurrents[0].service}
            status={recurrents[0].status}
          />
        )}
        <DonorsTitle>
          <DonorsTitleItem>Дата</DonorsTitleItem>
          <DonorsTitleItem w="5%" />
          <DonorsTitleItem w="10%">Сумма</DonorsTitleItem>
          <DonorsTitleItem>Электронная почта</DonorsTitleItem>
          <DonorsTitleItem>Телефон</DonorsTitleItem>
          <DonorsTitleItem>Карта</DonorsTitleItem>
          <DonorsTitleItem />
        </DonorsTitle>
        <ContentContainer>
          {recurrents.map((item: RecurrentType) => {
            const ServiceIconComponent = serviceIconsMap[item.service];
            const cardData = cardDataSelector(item, 'recurrent');

            return (
              <Donor key={item.createdAt}>
                <DonorItem>
                  {format(item.createdAt, 'DD MMM YYYY', { locale })}
                </DonorItem>

                <DonorItem w="5%">
                  {ServiceIconComponent ? (
                    <ServiceIconComponent />
                  ) : (
                    PaymentMethod[item.service]
                  )}
                </DonorItem>
                <DonorItem w="10%">
                  <strong>
                    {`${item.gross_sum} ${currency[item.gross_curr]}`}
                  </strong>
                </DonorItem>
                <DonorItem onClick={() => this.handleEmailClick(item)} isLink>
                  {decrypt(item.email, privateKey)}
                </DonorItem>
                <DonorItem>{decrypt(item.phone, privateKey) || '-'}</DonorItem>
                <DonorItem>
                  <strong>{lodashGet(cardData, 'cardNumber', '')}</strong>
                  {lodashGet(cardData, 'expDate', '')}
                  {!cardData && '—'}
                </DonorItem>
                <DonorItem>
                  <StarsIcon />
                  <Months>{item.transactionCount}</Months>
                  {`(${
                    differenceInMonths(new Date(), item.createdAt) + 1
                  } мес)`}
                </DonorItem>
              </Donor>
            );
          })}
        </ContentContainer>
      </div>
    );
  };

  render() {
    const { isModalOpen, donor } = this.state;
    return (
      <>
        {this.chooseRender()}
        {donor && (
          <EditDonor
            visible={isModalOpen}
            hide={() => this.closeModal()}
            donor={donor}
          />
        )}
      </>
    );
  }
}

export default withRouter(
  connect((state: FullStore): {
    privateKey: string,
  } => ({
    privateKey: state.privateKey.value,
  }))(Recurrents),
);
